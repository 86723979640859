import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center  h-[200px] mt-[200px]">
      <LineMdCoffeeLoop width="50px" height="50px" />
      <div className="text-xl text-backContrastColor my-4">{t('审核中')}</div>
      <div className="text-auxiliaryTextColor px-5">
        {t(
          '已完成个人信息提交流程，系统正在对提交的实名信息进行严谨核验，以确保信息的真实性和有效性，请保持联系方式畅通。',
        )}
      </div>
    </div>
  );
};
interface IProps {
  t: TFunction<'translation', undefined>;
}
