import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { IconParkSolidEditName } from '@/assets/icons/mine/IconParkSolidEditName';
import BaseInput from '@/components/base/baseInput';
import {
  RealNameTypeEnum,
} from '@/pages/mine/verified/useStateHooks';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { VerifiedNew } from '@/assets/icons/comm/verified_new';
import { toast } from '@/layouts/components/ToastMessage';
import { useMemo, useState } from 'react';
import {SelectIcon}  from "@/assets/icons/comm/Select"
import PageEnum from '@/enums/pageEnum';
import { history } from '@umijs/max';

export default ({
                  setTabSelected,
                  setForm,
                  form,

                  t,
                  realNameType,
                  realNameTypeValue,
                  setRealNameTypeValue,
                  areaCode,
                  subRealnameLoding,
                  nationality,
                }: any) => {

  return (
    <>
      <div className="mt-6 flex  flex-col gap-3">
        <div className='w-full h-[56px] px-3 py-2 rounded-[8px] flex justify-start bg-backgroundAuxiliaryColor' onClick={()=>{
          history.push(PageEnum.CHOOSE_NATIONALITY)
        }}>
           <div className='w-full f-[40px] flex justify-between items-center'>
            {<div className='text-[14px]'>{!nationality?t("国籍"):nationality}</div>}
              <SelectIcon className='relative'/>
            </div> 
        </div>
        {/* <Autocomplete
          className="w-full"
          label={t('国籍')}
          style={{ height: 38 }}
          defaultItems={[form.nationality]}
          inputValue={form.nationality}
          onInputChange={(e) => {
            setForm((prev) => ({ ...prev, nationality: e }));
          }}
          classNames={{
            trigger: `bg-[transparent] rounded-md h-[50px]`,

          }}
          inputProps={{
            classNames: {
              input:
                'bg-[transparent] translate-y-[10px] h-full rounded-md caret-darkBrandColor text-backContrastColor !shadow-none',
              inputWrapper:
                'bg-[transparent] h-full rounded-md bg-backgroundAuxiliaryColor !shadow-none group-data-[focus=true]:border-primary',
            },
          }}
          listboxProps={{
            className: 'bg-background',
          }}
          popoverProps={{
            classNames: {
              content: 'bg-background h-full text-foreground ',
            },
          }}
        >
          {areaCode?.map?.((animal: any) => (
            <AutocompleteItem
              startContent={
                <img
                  src={animal?.iconUrl}
                  className="w-6 h-6 object-cover rounded-full"
                  alt=""
                />
              }
              key={animal.alias}
              value={animal.alias}
            >
              {animal.alias}
            </AutocompleteItem>
          ))}
        </Autocomplete> */}
        <BaseInput
          className=""
          label={t('姓')}
          value={form?.surname}
          classNames={{
            inputWrapper: '!border-0 !shadow-none bg-backgroundAuxiliaryColor',
          }}

          onChange={(e) => {
            setForm((prev) => ({ ...prev, surname: e.target.value?.trim() }));
            //   setSurname( e.target.value )
          }}
          // startContent={
          //   <IconParkSolidEditName className="text-auxiliaryTextColor" />
          // }
        />
        <BaseInput
          className=""
          label={t('名')}
          value={form?.name}
          classNames={{
            inputWrapper: '!border-0 !shadow-none bg-backgroundAuxiliaryColor',
          }}
          onChange={(e) => {
            setForm((prev) => ({ ...prev, name: e.target.value?.trim() }));
            // console.log(e.target.value)
            //   setName(e.target.value)
          }}
          // startContent={
          //   <IconParkSolidEditName className="text-auxiliaryTextColor" />
          // }
        />
        <Select
          className="w-full "
          style={{ height: 40 }}
          selectedKeys={[realNameTypeValue]}
          onChange={(e) => {
            if (e.target.value) {
              setRealNameTypeValue(e.target.value as RealNameTypeEnum);
            }
          }}
          label={t('证件类型')}
          classNames={{
            base: '!rounded-md',
            trigger: `bg-[transparent] border-1 border-borderColor rounded-md h-[50px]`,
            mainWrapper: '!border-0 !shadow-none rounded-md',
            trigger: '!border-none rounded-md !shadow-none',
          }}
          popoverProps={{
            classNames: {
              content: 'bg-backgroundAuxiliaryColor text-foreground',
            },
          }}
        >
          {realNameType.map((animal) => (
            <SelectItem key={animal.value} value={animal.value}>
              {animal.text}
            </SelectItem>
          ))}
        </Select>
        <BaseInput
          value={form.certificateNumber}
          label={t('证件号码')}
          classNames={{
            base: 'border-none shadow-none',
            inputWrapper: '!border-0 !shadow-none bg-backgroundAuxiliaryColor',
          }}
          onChange={(e) => {
            setForm((prev) => ({
              ...prev,
              certificateNumber: e.target.value?.trim(),
            }));
          }}
          // startContent={
          //   <IconParkSolidEditName className="text-auxiliaryTextColor" />
          // }
        />
        <Button
          isLoading={subRealnameLoding}
          spinner={<LoadingSvg />}
          className="mainColorButton  w-full mt-4"
          onClick={() => {
            if (!nationality) {
              return toast.error(t('请选择国籍'));
            }
            if (!form?.surname) {
              return toast.error(t('请输入姓'));
            }
            if (!form?.name) {
              return toast.error(t('请输入名'));
            }
            if (!form.certificateNumber) {
              return toast.error(t('请输入证件号码'));

            }
            if (!realNameTypeValue) {
              return toast.error(t('请选择证件类型'));
            }

            // 下一步
            setTabSelected(2);
          }}
        >
          {t('下一步')}
        </Button>
        <div className="w-full  flex items-center justify-center mt-6 text-[12px] leading-[24px]">

          <VerifiedNew className="mr-2" />
          {t('信息加密处理，保障您的信息安全')}
        </div>
      </div>
    </>
  );
};
