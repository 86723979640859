import BaseModal from '@/components/base/baseModal';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ children, privacyAgreement }: IProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <BaseModal title={t('实名协议')} isOpen={isOpen} onClose={onClose}>
        <div className="mt-4 mb-10 px-4 ">
          <ScrollShadow hideScrollBar className="h-[50vh] sm:h-[40vh]">
            <div className="my-2 min-h-[30vh] w-full overflow-x-hidden bg-background text-foreground">
              <FeaturesRenderText data={privacyAgreement} />
            </div>
          </ScrollShadow>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  children: ReactNode;
  privacyAgreement: any;
}
