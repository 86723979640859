import type { SVGProps } from 'react';

export function Camera(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="hsl(var(--nextui-primary)" fill-opacity="0.8" />
      <g clip-path="url(#clip0_2797_1935)">
        <path
          d="M27.7381 14.0479H24.2857L23.2143 12.2622C23.0952 11.9051 22.6191 11.667 22.2619 11.667H17.7381C17.381 11.667 16.9048 11.9051 16.7857 12.2622L15.7143 14.0479H12.2619C11.3095 14.0479 10.4762 14.8813 10.4762 15.8337V26.5479C10.4762 27.5003 11.3095 28.3337 12.2619 28.3337H27.7381C28.6905 28.3337 29.5238 27.5003 29.5238 26.5479V15.8337C29.5238 14.8813 28.6905 14.0479 27.7381 14.0479ZM20 25.9527C17.0238 25.9527 14.6429 23.5718 14.6429 20.5956C14.6429 17.6194 17.0238 15.2384 20 15.2384C22.9762 15.2384 25.3571 17.6194 25.3571 20.5956C25.3571 23.5718 22.9762 25.9527 20 25.9527Z"
          fill="white"
        />
        <path
          d="M16.4286 20.5949C16.4286 21.5421 16.8049 22.4505 17.4746 23.1202C18.1444 23.79 19.0528 24.1663 20 24.1663C20.9472 24.1663 21.8556 23.79 22.5254 23.1202C23.1952 22.4505 23.5714 21.5421 23.5714 20.5949C23.5714 19.6477 23.1952 18.7393 22.5254 18.0695C21.8556 17.3997 20.9472 17.0234 20 17.0234C19.0528 17.0234 18.1444 17.3997 17.4746 18.0695C16.8049 18.7393 16.4286 19.6477 16.4286 20.5949Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2797_1935">
          <rect
            width="19.0476"
            height="19.0476"
            fill="white"
            transform="translate(10.4762 10.4771)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
