import type { SVGProps } from 'react';

export function VerifiedNew(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.864 3.25938L8.27524 0.82738C8.14404 0.76978 7.99524 0.76978 7.86404 0.82738L2.27524 3.25938C2.18307 3.29915 2.10456 3.36505 2.0494 3.44892C1.99424 3.5328 1.96485 3.63099 1.96484 3.73138V8.24658C1.96484 12.8226 7.66884 15.0562 7.91204 15.149C7.97124 15.1714 8.03364 15.1826 8.09604 15.1826C8.16004 15.1826 8.22404 15.1698 8.28484 15.1458C8.52484 15.0514 14.1728 12.773 14.1728 8.24498V3.73138C14.1744 3.52658 14.0528 3.34098 13.864 3.25938Z"
        fill="#00CA83"
      />
      <path
        d="M7.54721 9.91193C7.42561 9.91193 7.31041 9.86393 7.22561 9.77753L5.40321 7.95673C5.31791 7.87111 5.27002 7.75518 5.27002 7.63433C5.27002 7.51348 5.31791 7.39755 5.40321 7.31193C5.48851 7.22665 5.60419 7.17875 5.72481 7.17875C5.84543 7.17875 5.96111 7.22665 6.04641 7.31193L7.54241 8.80793L10.0896 6.19193C10.2656 6.01273 10.5536 6.00793 10.7328 6.18393C10.912 6.35993 10.9168 6.64793 10.7408 6.82713L7.87201 9.77433C7.78721 9.86233 7.67041 9.91033 7.54721 9.91193Z"
        fill="currentColor"
      />
    </svg>
  );
}
