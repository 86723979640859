import { Camera } from '@/assets/icons/comm/Camera';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { VerifiedNew } from '@/assets/icons/comm/verified_new';
import BaseUpload from '@/components/base/baseUpload';
import ThemeEnum from '@/enums/themeEnum';
import { toast } from '@/layouts/components/ToastMessage';
import { Button, Checkbox } from '@nextui-org/react';
import { useTheme } from 'next-themes';
import VerifiedPrivacyAgreementDrawer from './VerifiedPrivacyAgreementDrawer';

export default ({
  setTabSelected,
  setForm,
  privacyAgreement,
  form,
  checked,
  setChecked,
  userRealName,
  t,
  realNameTypeValue,
  currentTip,
  subRealnameLoding,
  nationality,
}: any) => {
  const { theme, setTheme } = useTheme();

  return (
    <>
      <div>
        {currentTip?.tips?.map((item: any, index) => {
          return (
            <div className="my-3 w-[240px] mx-auto" key={item.id}>
              {/* <div className="flex items-center font-bold mb-2">
                {item?.label}
              </div> */}
              <BaseUpload
                currentUrls={form[`info${index + 1}`]}
                onChange={(url: any) => {
                  setForm((prevState) => {
                    return {
                      ...prevState,
                      [`info${item.id}`]: url as string,
                    };
                  });
                }}
              >
                <div className="bg-background  w-full flex flex-col gap-2 justify-center items-center">
                  <div className="w-[240px] h-[120px] flex justify-center items-center">
                    <img
                      className="w-[240px] h-[120px] object-cover"
                      src={
                        theme === ThemeEnum.light
                          ? item?.lightImg
                          : item?.darkImg
                      }
                      alt=""
                    />
                    <Camera className="absolute" />
                  </div>

                  <span className="text-xs text-auxiliaryTextColor">
                    {item?.tip}
                  </span>
                </div>
              </BaseUpload>
            </div>
          );
        })}

        <div className="mt-6">
          <div className="flex items-center">
            <Checkbox
              size="sm"
              defaultSelected
              color="default"
              isSelected={checked}
              onValueChange={setChecked}
            >
              <span className="mr-0.5 text-xs">{t('我已详细阅读并同意')}</span>
            </Checkbox>
            <VerifiedPrivacyAgreementDrawer privacyAgreement={privacyAgreement}>
              <span className="ml-0.5 text-darkBrandColor text-xs">
                {`${t('实名协议')}`}
              </span>
            </VerifiedPrivacyAgreementDrawer>
          </div>
        </div>

        <Button
          isLoading={subRealnameLoding}
          spinner={<LoadingSvg />}
          className="mainColorButton   w-full mt-4"
          onClick={() => {
            setTimeout(() => {
              if (!nationality) return toast.error(t('请选择国籍'));
              if (!form.certificateNumber)
                return toast.error(t('请填写身份证号码'));
              if (currentTip?.tips.length === 1) {
                if (!form.info1) return toast.error(t('请上传证件'));
              }

              if (currentTip?.tips?.length && currentTip?.tips?.length === 2) {
                if (!form.info1 || !form.info2)
                  return toast.error(t('请上传证件'));
              }

              if (currentTip?.tips?.length && currentTip?.tips?.length === 3) {
                if (!form.info1 || !form.info2 || !form.info3)
                  return toast.error(t('请上传证件'));
              }

              if (!checked) return toast.error(t('请勾选协议'));

              let info = form.info1 + ',' + form.info2;
              if (form.info3) {
                info = form.info1 + ',' + form.info2 + ',' + form.info3;
              }

              userRealName({
                ...form,
                info,
                nationality,
                realname: form.name + ' ' + form.surname,
                realNameTypeValue,
              });
            });
          }}
        >
          {t('提交')}
        </Button>
        <Button
          className="mainColorButton  w-full mt-4 !bg-backgroundAuxiliaryColor !text-backContrastColor"
          onClick={() => {
            // 下一步
            setTabSelected(1);
          }}
        >
          {t('上一步')}
        </Button>
        <div className="w-full  flex items-center justify-center mt-6 text-[12px] leading-[24px]">
          <VerifiedNew className="mr-2" />
          {t('信息加密处理，保障您的信息安全')}
        </div>
      </div>
    </>
  );
};
