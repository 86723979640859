import type { SVGProps } from 'react';

export function SelectIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <>
      <svg
        aria-hidden="true"
        fill="none"
        focusable="false"
        height="1em"
        role="presentation"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        width="1em"
        data-slot="selectorIcon"
        class="absolute end-3 w-4 h-4 transition-transform duration-150 ease motion-reduce:transition-none data-[open=true]:rotate-180"
        {...props}
      >
        <path d="m6 9 6 6 6-6"></path>
      </svg>
    </>
  );
}
