import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import { toast } from '@/layouts/components/ToastMessage';
import VerifiedUnderReview from '@/pages/mine/verified/components/VerifiedUnderReview';

import { VerifiedStatesEnum } from '@/enums/businessEnum';
import VerifiedRealNameForm_new from '@/pages/mine/verified/components/VerifiedRealNameForm_new';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

import createVerifiedModel from '@/pages/mine/verified/useStateHooks';

export default () => {
  const { user } = useModel('user');
  const { t } = useTranslation();
  const model = createVerifiedModel();

  const { setNationality ,setForm} = model;
  if (user?.realnameStatus === VerifiedStatesEnum.REAL) {
    toast.success(t('实名认证成功'));
    history.replace(PageEnum.HOME);
  }
  return (
    <div className='overflow-hidden'>
      <BaseTopNav
        title={t('实名认证')}
        onBack={() => {
          history.back();
          setForm({});
          setNationality(null);
        }}
      />
      <div className="px-4 mt-6 f-ull">
        {/*未实名*/}
        {user.realnameStatus !== VerifiedStatesEnum.UNDER && (
          <VerifiedRealNameForm_new />
        )}
        {/*审核中状态*/}
        {user.realnameStatus === VerifiedStatesEnum.UNDER && (
          <VerifiedUnderReview />
        )}
      </div>
    </div>
  );
};
