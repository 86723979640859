import BaseTabs from '@/components/base/baseTabs';
import { useEffect, useState } from 'react';
import VerifiedRealNameForm_one from './VerifiedRealNameForm_one';
import VerifiedRealNameForm_two from './VerifiedRealNameForm_two';
import { cn } from '@/utils';
import { toast } from '@/layouts/components/ToastMessage';
import createVerifiedModel from '@/pages/mine/verified/useStateHooks';

export default () => {

  const model = createVerifiedModel();

  const {
    form,
    t,
    realNameTypeValue,
    nationality
  } = model;

  const [tabSelected, setTabSelected] = useState(1);
  const tabOptions = [
    { text: t('录入信息'), value: 1 },
    { text: t('上传证件照'), value: 2 },
  ];

    // 添加 useEffect 监听 tabSelected 变化
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // 添加平滑滚动效果
      });
    }, [tabSelected]);
  return (
    <>
      {/* 筛选 */}
      <div className="grid  grid-cols-2 gap-2 text-auxiliaryTextColor text-xs">
        <div onClick={() => {
          setTabSelected(1);
        }}>
          <span className={cn(`text-xs mb-2 `, {
            'text-backContrastColor': tabSelected === 1,
          })}>{t('录入信息')}</span>
          <div className={cn(`w-full mt-1 h-[3px] bg-auxiliaryTextColor`, {
            'bg-backContrastColor': tabSelected === 1,
          })}></div>
        </div>
        <div className={cn(`text-xs `, {
          'text-backContrastColor': tabSelected === 2,
        })} onClick={() => {
          if (!nationality) {
            return toast.error(t('请选择国籍'));
          }
          if (!form?.surname) {
            return toast.error(t('请输入姓'));
          }
          if (!form?.name) {
            return toast.error(t('请输入名'));
          }
          if (!form?.name) {
            return toast.error(t('请输入名'));
          }
          if (!form?.certificateNumber) {
            return toast.error(t('请输入证件号码'));
          }
          setTabSelected(2);
        }}>
          <span className='mb-2 '>{t('上传证件照')}</span>
          <div className={cn(`w-full mt-1 h-[3px] bg-auxiliaryTextColor`, {
            'bg-backContrastColor': tabSelected === 2,
          })}></div>
        </div>
      </div>

      <div>
        {/* 录入信息 */}
        {+tabSelected === 1 && (
          <VerifiedRealNameForm_one {...model} setTabSelected={setTabSelected} />
        )}
        {/* 上传证件照 */}
        {+tabSelected === 2 && (
          <VerifiedRealNameForm_two {...model} setTabSelected={setTabSelected} />
        )}
      </div>
    </>
  );
};
